/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */

'use client';

import React, { useEffect, useState } from 'react';
import { FaCircleCheck } from 'react-icons/fa6';
import { PREMIUM_PRICE_MONTH, PRO_PRICE_MONTH } from '@/constant';
import { usePathname, useRouter } from 'next/navigation';
import axios from 'axios';
import toast from 'react-hot-toast';
import Link from 'next/link';
import { useAuth } from '@clerk/nextjs';
import { Metadata } from 'next';
import Loading from './loading';
import { Button } from './ui/button';

export const metadata: Metadata = {
  title: 'Pricing',
};
const PricingPlan = () => {
  const [loadingPlan, setLoadingPlan] = useState<string | null>(null); // Track loading state for individual plans
  const [isYearly, setIsYearly] = useState(false);
  const { isSignedIn } = useAuth();
  const pathname = usePathname();
  const router = useRouter();

  const togglePlan = () => {
    setIsYearly(!isYearly);
  };

  const onSubscribe = async (plan: string) => {
    try {
      if (plan !== 'basic') {
        const response = await axios.get(`/api/stripe?plan=${plan}`);
        window.location.href = (await response).data.url;
      } else {
        // Handle the basic plan case here
        router.push(isSignedIn ? '/dashboard' : '/sign-up');
      }
    } catch (error) {
      toast.error('Something went wrong');
    } finally {
      setLoadingPlan(null); // Reset loading state after operation
    }
  };

  const handleGetStartedClick = async (plan: string) => {
    setLoadingPlan(plan); // Set loading state for the specific plan
    if (isSignedIn) {
      await onSubscribe(plan);
    } else {
      router.push(`/sign-up?plan=${plan}`);
    }
  };

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <div className={`${pathname === '/subscription' ? 'bg-gray-200 mt-12 rounded-lg min-h-screen lg:px-24' : 'py-8'} font-lato`}>
      <h2 className="text-3xl pt-4 font-bold mb-6 text-[#D5006D] section-heading text-center">Choose Your Plan</h2>
      <div className="flex items-center justify-center mb-2 mt-8">
        <span className={`mr-2 text-lg ${!isYearly ? 'font-extrabold text-deep-blue' : ''}`}>Monthly</span>
        <label
          className="switch relative inline-block w-12 h-6"
          htmlFor="planToggle"
        >
          <input
            id="planToggle"
            type="checkbox"
            className="opacity-0 w-0 h-0"
            checked={isYearly}
            onChange={togglePlan}
            aria-label="Toggle between monthly and yearly plans"
          />
          <span className="slider round absolute cursor-pointer top-0 left-0 right-0 bottom-0 bg-[#D5006D] rounded-full" />
          <span className={`dot absolute top-1 left-1 w-4 h-4 bg-white rounded-full transition ${isYearly ? 'transform translate-x-6' : ''}`} />
        </label>
        <span className={`ml-2 text-lg ${isYearly ? 'font-extrabold text-deep-blue' : ''}`}>Yearly</span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {/* Basic Plan */}
        <div className="max-w-xs bg-deep-blue m-4 p-6 text-gray-200 rounded-lg min-h-[400px]">
          <h3 className="pl-4 text-xl font-semibold mb-4">Basic</h3>
          <p className="pl-4 text-2xl mb-6">Free</p>
          <ul className="pl-4 text-left min-h-[240px] text-gray-200">
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Personal Use Only
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              3 Recipe Credits
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Recipe Download
            </li>
          </ul>
          <Link href={isSignedIn ? '/dashboard' : '/sign-up'}>
            <button
              onClick={() => handleGetStartedClick('basic')}
              disabled={pathname === '/subscription'}
              type="button"
              className={`text-base w-full ${pathname === '/subscription' ? 'bg-gray-500 cursor-not-allowed' : 'bg-gray-100 hover:bg-gray-200'} py-2 rounded-lg font-semibold text-deep-blue px-12`}
              aria-disabled={pathname === '/subscription'}
            >
              {loadingPlan === 'basic' ? <Loading loadingtext="Loading" /> : (pathname === '/subscription' ? 'Not Available' : 'Get Started')}
            </button>
          </Link>
        </div>
        {/* Premium Plan */}
        <div className="plan max-w-xs bgc-color text-gray-200 m-4 p-6 rounded-lg min-h-[400px]">
          <h3 className="pl-4 text-xl font-bold mb-4">Premium</h3>
          <p className="pl-4 text-2xl mb-6">{isYearly ? '$30/year' : `$${PREMIUM_PRICE_MONTH}`}</p>
          <ul className="pl-4 text-left min-h-[240px] text-gray-200">
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Personal Use Only
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Unlimited Recipe Credits
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Unlimited Cocktail Recipe Credit
            </li>

            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Download Existing Recipes
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Personalized AI
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              No Ads
            </li>
          </ul>
          <Button
            type="button"
            className="text-base w-full bg-gray-100 py-2 rounded-lg font-semibold text-deep-blue hover:bg-gray-200 px-12"
            onClick={() => handleGetStartedClick('premium')}
            disabled={loadingPlan === 'premium'}
          >
            {loadingPlan === 'premium' ? <Loading loadingtext="Loading" /> : 'Get Started'}
          </Button>
        </div>
        {/* Pro Plan */}
        <div className="plan bg-gradient-to-r from-deep-blue to-red text-gray-200 m-4 p-6 rounded-lg min-h-[430px] max-w-xs">
          <h3 className="pl-4 text-xl font-bold mb-4 text-gray-200">Pro</h3>
          <p className="pl-4 text-2xl mb-6">{isYearly ? '$60/year' : `$${PRO_PRICE_MONTH}`}</p>
          <ul className="pl-4 text-left min-h-[240px] text-gray-200">
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              <span>Everything in Premium</span>
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Image Generations (Beta)
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Privacy Mode
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Recipe Modifications
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Commercial License to Use Recipes
            </li>
            <li className="mb-2 flex items-center gap-1">
              <FaCircleCheck className="text-deep-green" />
              Personalized AI
            </li>
          </ul>
          <Button
            type="button"
            className="text-base w-full bg-gray-100 py-2 rounded-lg font-semibold text-deep-blue hover:bg-gray-200 px-12"
            onClick={() => handleGetStartedClick('pro')}
            disabled={loadingPlan === 'pro'}
          >
            {loadingPlan === 'pro' ? <Loading loadingtext="Loading" /> : 'Get Started'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PricingPlan;
